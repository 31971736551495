import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";
import AuthContextProvider from "./store/AuthContext";
import { ProtectedRoute, RedirectAuthUser } from "./components";
import AdminDashboardLayout from "./AdminDashboardLayout";

function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <RedirectAuthUser>
              <LoginPage />
            </RedirectAuthUser>
          }
        />
        <Route
          exact
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          }
        />
        <Route exact path="*" element={<Navigate to="/admin" />} />
        <Route exact path="/dashboard/*" element={<AdminDashboardLayout />} />
      </Routes>
    </AuthContextProvider>
  );
}

export default App;
