import React from 'react';
import {
    AspectRatio,
    Box,
    Center,
    HStack,
    GridItem,
    LinkBox,
    Text,
  } from '@chakra-ui/react';
import { AiFillPlayCircle } from 'react-icons/ai';
import { useDisclosure } from '@chakra-ui/hooks';
import { BsThreeDots } from 'react-icons/bs';

import AppModal from './AppModal';
import AppButton from './AppButton';


export default function VideoCard({ title, thumbnail, videourl, description, handleDelete }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const DeleteVideo = () => {
        let { isOpen, onOpen, onClose } = useDisclosure();

        return (
            <>
                <Box 
                    position='absolute'
                    top='0'
                    right='2'
                >
                    <BsThreeDots 
                        size='20px' 
                        color='#FCFDFF'
                        cursor='pointer'
                        onClick={onOpen}
                    />
                </Box>

                <AppModal 
                   headerTitle='Are you sure you want to delete?'
                   headerStyle={{
                       'color': '#162B4D',
                       'fontSize': '14px',
                       'textAlign': 'center',
                       'fontWeight': 'semibold'
                   }}
                   containerStyle={{
                       'pt': '1rem',
                       'pb': '1rem'
                   }}
                    isOpen={isOpen}
                    isCentered={true}
                    onClose={onClose}
                    footerVisible={false}
                >
                    <Center>
                        <HStack spacing={4}>
                            <AppButton 
                                buttonStyle={{
                                    'bgColor': '#ffffff',
                                    'px': '2rem',
                                    'py': '1rem',
                                    'size': 'md',
                                    'fontSize': '12px',
                                    'fontWeight': 'medium',
                                    'color': '#2D46B2',
                                    'borderColor': '#DFE5F0',
                                    'borderWidth': '1px'
                                }}
                                label='No'
                                onClick={onClose}
                            />

                            <AppButton 
                                buttonStyle={{
                                    'bgColor': '#E5EFFF',
                                    'px': '2rem',
                                    'py': '1rem',
                                    'size': 'md',
                                    'fontSize': '12px',
                                    'fontWeight': 'medium',
                                    'color': '#2D46B2',
                                    'borderColor': '#DFE5F0',
                                    'borderWidth': '1px'
                                }}
                                label='Yes, I want to delete'
                                onClick={() => {
                                    onClose();
                                    handleDelete();
                                }}
                            />
                        </HStack>   
                    </Center>
                </AppModal>
            </>
        )
    }

    return (
        <>
            <GridItem>
                <LinkBox>
                    <Box
                        borderRadius="2xl"
                        overflow="hidden"
                        boxShadow="xl"
                        backgroundPosition={['center', 'center', '']}
                        backgroundRepeat="no-repeat"
                        display="flex"
                        flexDirection="column"
                        bgImage={thumbnail}
                        bgSize='cover'
                    >
                        <DeleteVideo />
                        <Center width="100%" h={['200px', '230px', '250px']}>
                            <AiFillPlayCircle 
                                size={70} 
                                color="black" 
                                opacity={.8} 
                                onClick={onOpen}
                                cursor='pointer'
                            />
                        </Center>
                        <Box
                            display="flex"
                            alignItems="baseline"
                            px="5"
                            py="2"
                            w="100%"
                            height={['90px', '90px', '70px']}
                            bg="white"
                        >
                            <Text
                                color="#162B4D"
                                fontSize="0.875rem"
                                fontWeight="medium"
                                textDecorationLine="none"
                                textDecoration="none"
                                marginTop="0.25rem"
                            >
                                {description}
                            </Text>
                        </Box>
                    </Box>
                </LinkBox>
            </GridItem>

            <AppModal 
                headerTitle={title}
                headerStyle={{}}
                containerStyle={{}}
                isOpen={isOpen}
                isCentered={true}
                onClose={onClose}
                footerVisible={false}
            >
                <AspectRatio>
                    <iframe
                        src={videourl}
                        title={title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
              </AspectRatio>
            </AppModal>
        </>
    )
}