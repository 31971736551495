import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/modal';


export default function AppModal({
    headerTitle,
    headerStyle,
    containerStyle,
    children,
    isOpen,
    isCentered,
    onClose,
    footerVisible=false
}) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} {...containerStyle} isCentered={isCentered}>
            <ModalOverlay />
            <ModalContent {...containerStyle}>
                <ModalHeader 
                    {...headerStyle}
                >
                    {headerTitle}
                </ModalHeader>
                <ModalCloseButton 
                    _focus={{ 'outline': 'none' }} 
                    _hover={{ 'backgroundColor': '#637DA6'}} 
                    color='#2D46B2'
                />
            <ModalBody>
                {children}
            </ModalBody>

            <ModalFooter display={footerVisible ? 'block' : 'none'}>
                {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button variant='ghost'>Secondary Action</Button> */}
            </ModalFooter>
            </ModalContent>
      </Modal>
    )
}