import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Text,
  Image,
  Flex,
} from '@chakra-ui/react';
import { 
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/form-control';
import { useToast } from '@chakra-ui/toast';
import { useForm } from 'react-hook-form';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { whiten } from '@chakra-ui/theme-tools';
import { LOGIN_BG } from '../../constants/images';
import { AppButton, AppForm } from '../../components';
import { logInApi } from '../../api/auth.api';
import { AuthContext } from '../../store/AuthContext';

  
  export default function LoginPage() {
    const { admin, dispatch } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);

    const {
      handleSubmit,
      register,
      setError,
      formState: { errors },
    } = useForm();
  
    const toast = useToast();
  
    const [show, setShow] = useState(false);
  
    const handleClick = () => setShow(!show);
  
    const onSubmit = async (payload, e) => {
      setLoading(true);
      
      try {
        const { data, error} = await logInApi(payload);

        setLoading(false);
        
        if (error) throw error;

        dispatch({
          type: 'LOGIN_ADMIN',
          payload: data?.accessToken
        });
        
        navigate('/admin', { replace: true });
      } catch (error) {
        setLoading(false);
        
        toast({
          title: 'Error',
          description: `${error || "Couldn't login. Please Try again."}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    };
  
    const registerOptions = {
      email: {
        required: 'This is required',
        pattern: {
        //   value: EMAIL_PATTERN,
          message: 'Invalid email',
        },
      },
      password: {
        required: 'This is required',
        minLength: {
          value: 8,
          message: 'Minimum length should be 8.',
        },
      },
    }
  
    return (
      <Flex
        w="100%"
        h="100vh"
        bg={['#fff', '#fff', '#011B66']}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position={['relative', '', 'fixed']}
      >
        <Image src={LOGIN_BG} position="absolute" top={0} left={0} />
  
        <Flex
          alignItems="center"
          bg="white"
          flexDir="column"
          mt="2.25rem"
          maxW="35rem"
          w="90%"
          minH={['30rem', '30rem', '30rem']}
          border={['none', '', '3px solid rgba(240, 242, 252, 0.5)']}
          boxShadow={['none', '', '0 4px 1rem rgba(45, 70, 178, 0.08)']}
          borderRadius="0.8rem"
        >
          <Flex
            alignItems="center"
            w="80%"
            minW="19rem"
            maxW="35rem"
            flexDir="column"
          >
            <Heading
              align="center"
              minW={['71px', '', '101px']}
              minH={['2rem', '', '3rem']}
              lineHeight={['2rem', '', '2rem']}
              fontSize={['1.3125rem', '', '1.5rem']}
              fontWeight={['600', '', '500']}
              color="#2D46B3"
              mt="1.5rem"
            >
              Welcome back
            </Heading>
  
            <Text
              w={['100%', '', '21.25rem']}
              fontSize="0.875rem"
              lineHeight="1.5rem"
              align="center"
              color="#162B4D"
              m="0"
              mt={['0.8rem', '', '0']}
            >
              Login to use Admin features.
            </Text>
  
            <Box w="100%">
              <form
                onSubmit={handleSubmit(onSubmit)}
               >
                <Box w="100%" mt={['1.5rem', '', '1rem']}>
                  <FormControl mt="1rem" isInvalid={errors.email} isRequired>
                    <FormLabel
                      fontSize="0.875rem"
                      lineHeight="1.5rem"
                      color="#162B4D"
                    >
                      Email
                    </FormLabel>
                    <Input
                      id="email"
                      placeholder="email"
                      height="3rem"
                      {...register('email', registerOptions.email)}
                    />
                    <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                  </FormControl>
  
                  <FormControl mt="1rem" isInvalid={errors.password} isRequired>
                    <FormLabel
                      fontSize="0.875rem"
                      lineHeight="1.5rem"
                      color="#162B4D"
                    >
                      Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="password"
                        placeholder="password"
                        height="3rem"
                        type={show ? 'text' : 'password'}
                        {...register('password', registerOptions.password)}
                      />
                      <InputRightElement width="4.5rem" align="center">
                        <Button
                          size="sm"
                          onClick={handleClick}
                          align="center"
                          bg="transparent"
                          _hover={{
                            'outline': 'none'
                          }}
                        >
                          {show ? (
                            <AiFillEyeInvisible color="#4158BB" />
                          ) : (
                            <AiFillEye color="#4158BB" />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors?.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                
                <AppButton 
                  buttonStyle={{
                    'bgColor': '#2D46B2',
                    'px': '2rem',
                    'py': '1rem',
                    'size': 'md',
                    'fontSize': '12px',
                    'fontWeight': 'semibold',
                    'color': '#ffffff',
                    'width': '100%',
                    'variant': 'primary',
                    'mt': 10
                  }}
                  label='Login'
                  isCentered={true}
                  type='submit'
                  loading={loading}
                />
              </form>
            </Box>
           
          </Flex>
        </Flex>
      </Flex>
    );
  }
  
