import {client} from './client.js';
import { API_URL } from '../constants/urls.js';


export const logInApi = async (data) => {
    const res = await client({
        method: 'POST',
        url: `${API_URL}/api/admin/login`,
        payload: data
    });

    return res;
}