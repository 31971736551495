import {
  Box,
  Circle,
  Flex,
  HStack,
  IconButton,
  Text,
  Heading,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { useState, useContext } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { AppButton } from "../components/index";
import { useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { Route, Routes } from "react-router-dom";
import AdminSideBarNav from "../components/AdminSideBarNav";
import { AuthContext } from "../store/AuthContext";
import MobileAdminSideNavDrawer from "../components/MobileAdminSideNavDrawer";
import CouponsPage from "../pages/CouponsPage";
import DashboardPage from "../pages/DashboardPage";
import Promotions from "../pages/Promotions";
import { PALM } from "../constants/images";
// const PromotionsPage = lazy(() => import("../pages/Promotions"));

export default function AdminDashboardLayout() {
  const [isMobileDrawer, setIsMobileDrawer] = useState();

  const { dispatch, admin } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <Flex bg="#0A1636">
      <Box display={{ base: "none", lg: "block" }}>
        <AdminSideBarNav />
      </Box>
      <Box display={{ lg: "none" }}>
        <MobileAdminSideNavDrawer
          isOpen={isMobileDrawer}
          onClose={() => setIsMobileDrawer(false)}
        />
      </Box>

      <Box
        w={["100%", "", "", "calc(100% - 272px)"]}
        ml={{ lg: "auto" }}
        minH="100vh"
      >
        {/* top nav */}
        <Flex
          alignItems="center"
          bg="#0A1636"
          borderBottom="1px solid"
          borderColor="#1B2866"
          px={["16px", "", "32px"]}
          py="12px"
          position="fixed"
          top={0}
          right={0}
          left={{ base: "0", lg: "272px" }}
          zIndex={1}
        >
          <Heading
            as="h1"
            // color='#162B4D'
            color="#FFFFFF"
            fontSize="26px"
            fontWeight="bold"
          >
            Hello, {admin?.username}
          </Heading>
          <Image src={PALM} boxSize="30px" />
          <Box display={{ lg: "none" }}>
            <IconButton
              onClick={() => setIsMobileDrawer(true)}
              bg="transparent"
              color="#FFFFFF"
              minW="fit-content"
              h="fit-content"
              _focus={{ boxShadow: "none" }}
              p={0}
              icon={<FiMenu fontSize="24px" />}
            />
          </Box>
          <HStack ml="auto">
            <AppButton
              buttonStyle={{
                bgColor: "#2D46B2",
                px: "1.5rem",
                py: "1rem",
                size: "md",
                fontSize: "14px",
                fontWeight: "semibold",
                color: "#ffffff",
              }}
              label="Log Out"
              onClick={logout}
            />
          </HStack>
        </Flex>
        <Box h="73px"></Box>
        {/* ### top nav ### */}
        <Box py="32px" px={["16px", "", "32px"]}>
          <Routes>
            <Route exact path="" element={<DashboardPage />} />
            <Route exact path="promotions" element={<Promotions />} />
            <Route exact path="coupons" element={<CouponsPage />} />
          </Routes>
        </Box>
      </Box>
    </Flex>
  );
}
