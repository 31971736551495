import React from "react";
import { Heading, Box, Text, HStack, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import { PALM } from "../../../constants/images";

export default function IntroView({ admin }) {
  return (
    <>
      <VStack spacing={4} align="left" mb="2rem">
        <HStack display={{ base: "flex", lg: "none" }}>
          <Heading
            as="h1"
            // color='#162B4D'
            color="#FFFFFF"
            fontSize="26px"
            fontWeight="bold"
          >
            Hey, {admin?.username}
          </Heading>

          <Image src={PALM} boxSize="30px" />
        </HStack>

        <Box>
          <Heading
            as="h4"
            // color='#162B4D'
            color="#FFFFFF"
            fontSize="14px"
            fontWeight="semibold"
            mb=".1rem"
          >
            Promotional thumbnails
          </Heading>

          <Text color="#637DA6" fontSize="12px" fontWeight="medium">
            Image must be 1104 x 208
          </Text>
        </Box>
      </VStack>
    </>
  );
}
