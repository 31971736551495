import { Box, Center, HStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { BsThreeDots } from 'react-icons/bs';
import AppModal from './AppModal';
import AppButton from './AppButton';
import { useDisclosure } from '@chakra-ui/hooks';

export default function AppHorizontalCard({ containerStyle, deleteThumbnail, imageStyle, thumbnailURL }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Center 
                bg='#2D46B2' borderRadius='lg' 
                h={+imageStyle.height.split('rem')[0] + 2.5 + 'rem'}
                w={+imageStyle.minWidth?.split('rem')[0] + 1 + 'rem'}
                position='relative'
                {...containerStyle}
            >
                <Box >
                    <Image 
                        src={thumbnailURL}
                        {...imageStyle}
                    />
                </Box>
                    <Box 
                        position='absolute'
                        top='0'
                        right='2'
                    >
                        <BsThreeDots 
                            size='20px' 
                            color='#FCFDFF'
                            cursor='pointer'
                            onClick={onOpen}
                        />    
                    </Box>
            </Center>

            <AppModal 
                headerTitle='Are you sure you want to delete?'
                headerStyle={{
                    'color': '#162B4D',
                    'fontSize': '14px',
                    'textAlign': 'center',
                    'fontWeight': 'semibold'
                }}
                containerStyle={{
                    'pt': '1rem',
                    'pb': '1rem'
                }}
                isOpen={isOpen}
                isCentered={true}
                onClose={onClose}
                footerVisible={false}
            >
                <Center>
                    <HStack spacing={4}>
                        <AppButton 
                            buttonStyle={{
                                'bgColor': '#ffffff',
                                'px': '2rem',
                                'py': '1rem',
                                'size': 'md',
                                'fontSize': '12px',
                                'fontWeight': 'medium',
                                'color': '#2D46B2',
                                'borderColor': '#DFE5F0',
                                'borderWidth': '1px'
                            }}
                            label='No'
                            onClick={onClose}
                        />

                        <AppButton 
                            buttonStyle={{
                                'bgColor': '#E5EFFF',
                                'px': '2rem',
                                'py': '1rem',
                                'size': 'md',
                                'fontSize': '12px',
                                'fontWeight': 'medium',
                                'color': '#2D46B2',
                                'borderColor': '#DFE5F0',
                                'borderWidth': '1px'
                            }}
                            label='Yes, I want to delete'
                            onClick={() => {
                                onClose();
                                deleteThumbnail();
                            }}
                        />
                    </HStack>   
                </Center>
            </AppModal>
        </>
    )
}