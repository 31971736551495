import { Flex } from '@chakra-ui/layout';
import AppHorizontalCard from './AppHorizontalCard';


export default function DesktopThumbnail({ thumbnails, deleteThumbnail }) {
    return (
        <Flex flexWrap='wrap'>
            {thumbnails.map((thumbnailURL, index) => (
                <AppHorizontalCard  
                    key={`thumbnail-${index}`}
                    thumbnailURL={thumbnailURL}
                    containerStyle={{
                        'mr': '1rem',
                        'mb': '1rem'
                    }}
                    imageStyle={{
                        'minWidth': '20rem',
                        'borderRadius': 'lg',
                        'height': '10rem',
                    }}
                    deleteThumbnail={() => deleteThumbnail(thumbnailURL)}
                />
                ))
            }
        </Flex>
    )
}