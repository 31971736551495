export const uploadThumbnail = async (thumbnail, url) => {
  // console.log('url ', url)
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: thumbnail,
    });

    if (res.status !== 200 || !res.ok)
      throw new Error("Couldn't upload Thumbnail. Retry!");

    return res?.url.split("?")[0];
  } catch (error) {
    console.log(error);
  }
};
