import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { DeleteIcon } from "../../assets/Svg.js/Svg";
import CreatePromotionModal from "./PromotoinComponents/CreatePromotionModal";
import DeletePromotionModal from "./PromotoinComponents/DeletePromotionModal";
import EventForm from "../../components/EventForm.js";
import AllEvents from "../../components/AllEvents.js";

export default function Promotions() {
  const [isCreate, setIsCreate] = useState();
  const [deleteButton, setDeleteButton] = useState();
  const [isDeleteModal, setIsDeleteModal] = useState();

  return (
    <Box>
      <Text
        color="#EFF1F6"
        fontSize={["24px", "", "30px"]}
        lineHeight={["30px", "", "45px"]}
        fontWeight={600}
      >
        Create Event
      </Text>
      <EventForm />
      <AllEvents />
    </Box>
  );
}
