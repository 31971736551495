import { Image } from "@chakra-ui/image";
import { Center } from "@chakra-ui/react";
import { SCOREFAMLOGO } from "../constants/images";

export default function AppLogo({ imageStyle }) {
  return (
    <Center>
      <Image src={SCOREFAMLOGO} {...imageStyle} />
    </Center>
  );
}
