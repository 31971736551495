import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function GraphColumn({ greenBar, blueBar, days }) {
  return (
    <VStack spacing="10px">
      <Flex
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        w="8px"
        h={"152px"}
      >
        <Box
          bg="#55C388"
          h={greenBar}
          w="100%"
          borderRadius="2px 2px 0px 0px"
        ></Box>
        <Box bg="#023ED4" h={blueBar} w="100%"></Box>
      </Flex>
      <Text color="#BEC9DA" fontSize="14px" lineHeight="21px">
        {days}
      </Text>
    </VStack>
  );
}
