import { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext({ admin: ''});

const INITIAL_STATE = {
    email: null,
    username: null
}

const setItem = (key, value, ttl) => {
    const now = new Date();

    const item = {
        ...value,
        expiry: now.getTime() + ttl
    }

    localStorage.setItem(key, JSON.stringify(item));
}

const getItem = (key) => {
    const value = localStorage.getItem(key);

    if (!value) return null;

    const item = JSON.parse(value);

    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item;
} 

const authReducer = (state=INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'LOGIN_ADMIN':
            const { email, username } = jwtDecode(payload);

            setItem('token', payload, 3600000);

            return {
                email,
                username,
                token: payload
            }
        case 'LOGOUT':
            localStorage.removeItem('token');
            return {
                ...INITIAL_STATE 
            }
    }
}

export default function AuthContextProvider({ children }) {
    const [ token, dispatch ] = useReducer(authReducer, null, () => {
        const localAdminData = getItem('token');
        return localAdminData ? localAdminData : ''
    });

    useEffect(() => {
        setItem('token', token, 3600000);
    }, [ token ]);

    return (
        <AuthContext.Provider value={{ admin: token, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}
