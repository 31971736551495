import { Box, Button, Image, VStack } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  CouponsIcon,
  DashboardIcon,
  PromotionsIcon,
} from "../constants/images";
import AppLogo from "./AppLogo";
import { RiAdminFill } from "react-icons/ri";

export default function AdminSideBarNav() {
  const location = useLocation();
  const router = useNavigate();

  const activePage = (title) => {
    return location.pathname === `/dashboard/${title}`;
  };

  const linkLists = [
    {
      title: "Dashboard",
      linkAddress: "",
      icon: DashboardIcon,
    },
    {
      title: "Promotions",
      linkAddress: "promotions",
      icon: PromotionsIcon,
    },
    {
      title: "Coupons",
      linkAddress: "coupons",
      icon: CouponsIcon,
    },
    {
      title: "Banner",
      linkAddress: "admin",
    },
  ];

  return (
    <Box
      position={{ lg: "fixed" }}
      w="272px"
      h="100vh"
      bg="#121B45"
      backdropFilter="blur(12px)"
      px="14px"
      py="12px"
    >
      <Link to="#">
        <AppLogo imageStyle={{ with: "120px", height: "30px" }} />
      </Link>
      <VStack align="start" mt="50px">
        {linkLists.map((links, index) => (
          <Button
            onClick={() =>
              router(`${index === 3 ? "" : "/dashboard"}/${links.linkAddress}`)
            }
            key={index}
            w="100%"
            justifyContent="start"
            gap="19px"
            bg={activePage(links.linkAddress) ? "#3D33A9" : "transparent"}
            _hover={{ bg: "rgba(0,0,0,0.4)" }}
            _focus={{ boxShadow: "none" }}
            borderRadius="7px"
            color="#BEC9DA"
          >
            {index === 3 ? (
              <RiAdminFill fontSize="20px" />
            ) : (
              <Image
                src={links.icon}
                alt={links.title}
                width="20px"
                height="20px"
              />
            )}
            {links.title}
          </Button>
        ))}
      </VStack>
    </Box>
  );
}
