import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../store/AuthContext';


export default function RedirectAuthUser({ children }) {
    const { admin } = useContext(AuthContext);

    if (admin?.username) {
        return <Navigate to='/admin' replace /> 
    }

    return children
}