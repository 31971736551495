import { useRef, useState } from "react";
import { Box, Center, VStack, Text} from '@chakra-ui/layout';
import { FiUpload } from 'react-icons/fi';
import { Image } from '@chakra-ui/image';
import { useToast } from '@chakra-ui/toast'
import { API_URL } from "../constants/urls";


export default function UploadInput({ bucketType, title, setSelectedThumbnail, setUploadURL }) {
    const toast = useToast();

    const [ preview, setPreview ] = useState(null);
    
    const fileInputRef = useRef(null);

    const openSelectBox = () => fileInputRef.current.click();

    const getSecureUrl = async () => {
        try {
            const url = `${API_URL}/api/admin/s3-upload-url/${bucketType}`

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': '',
                },
            });

            if (response.status === 200 || response.status === 201) {
                const resJson = await response.json();

                return resJson?.url;
            }

            throw new Error("Couldn't fetch secure URL.");
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
            })
        }
    }


    const handleFileSelectAndUpload = async (event) => {
        const selectedFile = event.target.files[0];

        setPreview(URL.createObjectURL(selectedFile));

        setSelectedThumbnail(selectedFile);
        
        const url = await getSecureUrl(); 

        setUploadURL(url);
    }

    return (
        <Box width='100%' textAlign='center' mb='1rem'>
            <Text
                color='#162B4D'
                fontSize='12px'
                fontWeight='semibold'
                mb='.5rem'
            >
                {title}
            </Text>
            {
                preview ? (
                    <Center>
                        <Image 
                            src={preview}
                            height='7rem'
                        />
                    </Center>
                ) :
                (
                    <Center 
                        borderWidth='2px' 
                        borderColor='#BFDFFF' 
                        height='7rem'
                        borderRadius='md'
                        borderStyle='dashed'
                        width='100%'
                    >
                        <VStack spacing={1} cursor='pointer'>
                            <input 
                                style={{ display: 'none' }}
                                id='file-input'
                                type='file'
                                ref={fileInputRef}
                                onChange={handleFileSelectAndUpload}
                                accept="image/*"
                            />
                            <FiUpload 
                                as='input' 
                                type='file' 
                                color='#637DA6' 
                                size='26px'
                                onClick={openSelectBox} 
                            />
                            <Text 
                                color='#2D46B2'
                                fontSize='14px'
                                fontWeight='medium'
                            >
                                Upload
                            </Text> 
                        </VStack>
                    </Center>
                )
            }  
        </Box>
    )
}