import { Button } from "@chakra-ui/button";


export default function AppButton({ 
    buttonStyle, 
    label, 
    onClick,
    type,
    loading=false,
    disable=false
}) {
    return (
        <Button 
            {...buttonStyle}
            onClick={onClick}
            _hover={{
                'opacity': '.8'
            }}
            type={type}
            isLoading={loading}
            disabled={disable}
        >
            {label}
        </Button>
    )
}