import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../store/AuthContext';

export default function ProtectedRoute({ children }) {
    const { admin } = useContext(AuthContext);

    const location = useLocation();

    if (!admin?.username) {
        return (
            <Navigate 
                to='/'
                state={{ from: location }}
                replace
            />
        )
    }
    
    return children;
}
