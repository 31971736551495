

export default function AppForm({ children, onSubmit }) {
    return (
        <form
            onSubmit={onSubmit}
        >
            {children}
        </form>
    )
}