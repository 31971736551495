import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import CouponsTable from "./CouponsComponents/CouponsTable";
import CreateCouponModal from "./CouponsComponents/CreateCouponModal";

export default function CouponsPage() {
  const [isCoupon, setIsCoupon] = React.useState(false);

  return (
    <Box>
      <Text
        color="#EFF1F6"
        fontSize={["24px", "", "30px"]}
        lineHeight={["30px", "", "45px"]}
        fontWeight={600}
      >
        Coupons
      </Text>
      <Button
        onClick={() => setIsCoupon(true)}
        w="198px"
        h="48px"
        bg="#2258E1"
        border="1px solid #3D75FF"
        borderRadius="7px"
        color="#FFFFFF"
        fontSize="15px"
        lineHeight="24px"
        fontWeight={500}
        mt="17px"
        mb="24px"
      >
        Create new
      </Button>
      <Box>
        <CouponsTable />
      </Box>
      <CreateCouponModal isOpen={isCoupon} onClose={() => setIsCoupon(false)} />
    </Box>
  );
}
