export const client = async ({ method, url, payload, headers={}, token=null }) => {
    try {
        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': token,
                ...headers
            },
            body: payload ? JSON.stringify(payload) : null
        });

        const { data, error }  =  await response.json();

        if (error) return { data: null, error }
        
        if (response.status === 200 || response.status === 201) 
            return { data };


    } catch (error) {
        console.log(error)
    }
}