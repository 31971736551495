import { Box, Center, Image, Text } from "@chakra-ui/react";
import React from "react";

export default function GameNUserCard({ cardImg, title, amount }) {
  return (
    <Center
      justifyContent="space-between"
      h="106px"
      bg="linear-gradient(100.19deg, rgba(10, 22, 54, 0.4) 2.72%, rgba(8, 18, 43, 0.5) 100%)"
      backdropFilter="blur(16px)"
      borderRadius="8px"
      border="1px solid"
      borderColor="#8F7FEF #9747FF"
      pl={"20px"}
      pr={"26px"}
    >
      <Image src={cardImg} alt="user image" />
      <Box>
        <Text color="#BEC9DA" fontSize="12px" lineHeight="18px">
          {title}
        </Text>
        <Text color="#EFF1F6" fontSize="16px" lineHeight="24px">
          {amount}
        </Text>
      </Box>
    </Center>
  );
}
