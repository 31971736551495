import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { useToast } from "@chakra-ui/toast";

export default function CreateCouponModal({ isOpen, onClose }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [prefixText, setPrefixText] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const fetchCouponCodes = async () => {
    const url = `http://localhost:5000/api/coupon/all`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      console.log("This is data", data);
    }
  };

  const createCouponCode = async (prefixText, multiplier, expiryDate) => {
    try {
      //
      setIsLoading(true);
      //
      const response = await fetch(`https://api-v2.scorefam.org/api/coupon/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prefixText: prefixText,
          multiplier: multiplier,
          expiryDate: expiryDate,
        }),
      });
      if (response.status === 201) {
        //const data = await response.json();
        toast({
          title: "Success",
          description: `Coupon code created successfully, Please reload.`,
          //description: `An account with this email already exists, please check and try again!`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });

        setIsLoading(false);
      }

      if (response.status === 422) {
        //const data = await response.json();
        toast({
          title: "Error",
          description: `Coupon code already exist`,
          //description: `An account with this email already exists, please check and try again!`,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });

        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      toast({
        title: "Error",
        description: `Error generating booking code: ${err}`,
        //description: `An account with this email already exists, please check and try again!`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Modal
      size={"sm"}
      closeOnOverlayClick={true}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bg={
          "linear-gradient(101.24deg, rgba(0, 9, 31, 0.8) -1.38%, rgba(0, 9, 31, 0.65) 105.06%)"
        }
        backdropFilter="blur(30px)"
        border="1px solid rgba(125, 146, 181, 0.2)"
      >
        <ModalBody p={"24px 16px"}>
          <Input
            id="prefixText"
            h="56px"
            bg="rgba(12, 28, 69, 0.5)"
            borderRadius="7px"
            color="#EFF1F6"
            fontSize="14px"
            lineHeight="20px"
            placeholder="Coupon code"
            _placeholder={{ color: "#BEC9DA" }}
            style={{ border: "1px solid rgba(93, 119, 162, 0.3)" }}
            value={prefixText}
            onChange={(e) => setPrefixText(e.target.value)}
          />
          <Input
            id="multiplier"
            h="56px"
            bg="rgba(12, 28, 69, 0.5)"
            borderRadius="7px"
            color="#EFF1F6"
            fontSize="14px"
            lineHeight="20px"
            placeholder="Multiplier"
            _placeholder={{ color: "#BEC9DA" }}
            style={{ border: "1px solid rgba(93, 119, 162, 0.3)" }}
            my="8px"
            value={multiplier}
            onChange={(e) => setMultiplier(e.target.value)}
          />
          <Input
            id="expiryDate"
            h="56px"
            bg="rgba(12, 28, 69, 0.5)"
            borderRadius="7px"
            color="#EFF1F6"
            fontSize="14px"
            lineHeight="20px"
            placeholder="Expiry Date"
            _placeholder={{ color: "#BEC9DA" }}
            style={{ border: "1px solid rgba(93, 119, 162, 0.3)" }}
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
          <Button
            onClick={() => createCouponCode(prefixText, multiplier, expiryDate)}
            //onClick={onClose}
            isLoading={isLoading}
            w="100%"
            h="48px"
            bg="#3D75FF"
            border="1px solid #3D75FF"
            borderRadius="7px"
            fontSize="15px"
            lineHeight="24px"
            color="#FFFFFF"
            mt="32px"
          >
            Create New Coupon
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
