import { extendTheme } from "@chakra-ui/react";

const colors = {
    text: {
        white: '#ffffff',
        _grey2: '#637DA6',
        _darkBlue: '#162B4D',
        _blueMain: '#2D46B2',
        reddish: '#FF0000'
    },
    button: {
        default: '#2D46B2'
    }
}

export const theme = extendTheme({ colors });