import React, { useContext } from "react";
import { Box } from "@chakra-ui/layout";
import { AppNavbar } from "../../components";
import IntroView from "./views/IntroView";
import ThumbnailSection from "./views/ThumbnailSection";

import TutorialVideosSection from "./views/TutorialVideosSection";
import { AuthContext } from "../../store/AuthContext";
import AdminSideBarNav from "../../components/AdminSideBarNav";

export default function AdminPage() {
  const { admin } = useContext(AuthContext);

  return (
    <Box>
      <Box display={{ base: "none", lg: "block" }}>
        <AdminSideBarNav />
      </Box>
      <Box px={["1rem", "3rem"]} ml={{ lg: "272px" }} minH="100vh" bg="#0A1636">
        <AppNavbar />

        <IntroView admin={admin} />

        <ThumbnailSection />

        <TutorialVideosSection />
      </Box>
    </Box>
  );
}
