import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, 
  VStack, 
  Heading, 
  Container, 
  Text, 
  Textarea,
  IconButton, 
  useDisclosure, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody, 
  FormControl, 
  FormLabel, 
  Input, 
  Button,  
  useColorModeValue,
  Select,
  List,
  ListItem,
} from '@chakra-ui/react';
import { MdModeEdit } from "react-icons/md";

function AllEvents() {
  const [events, setEvents] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [onchainModal, setOnchainModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({
     question: '',
     eventDetails: {
      description: '',
      image: '',
      eventType: '',
    },
    result: { outcome: '' },
    tokenName: '',
    tokenPrice: '',
    optionOne: '',
    optionTwo: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://api-v2.scorefam.org/api/admin/get-events');
        setEvents(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchEvents();
  }, []);

  const handleEdit = (event) => {
    setSelectedEvent(event);
    onOpen();
  };

  const handleOnchainEdit = (event) => {
    setSelectedEvent(event);
    setOnchainModal(true);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in selectedEvent.eventDetails) {
      setSelectedEvent(prev => ({
        ...prev,
        eventDetails: { ...prev.eventDetails, [name]: value }
      }));
    }  else if (name in selectedEvent.result){
       setSelectedEvent(prev => ({
        ...prev,
        result: { ...prev.result, [name]: value }
      }));
    } else {
      setSelectedEvent(prev => ({ ...prev, [name]: value }));
    }
  };

  
  const handleUpdate = async () => {
    try {
      await axios.put(
        `https://api-v2.scorefam.org/api/admin/update-event/${selectedEvent.eid}`, 
        selectedEvent,
        {
          headers: {
          'x-api-key': 'd3e9b57f77c74e3a9c4f0f19c44a7a50f4cbb0958d4d50edfa6c52f81e0d44e9'
          }
        }
      );
      onClose();
      const updatedEvents = events.map(ev => ev.eid === selectedEvent.eid ? selectedEvent : ev);
      setEvents(updatedEvents);
    } catch (error) {
      console.error('Failed to update event:', error);
    }
  };

  const handleOnchainUpdate = async () => {
    try {
      await axios.put(`https://api-v2.scorefam.org/api/admin/resolve-event/${selectedEvent.eid}`, selectedEvent);
      setOnchainModal(false);
      const updatedEvents = events.map(ev => ev.eid === selectedEvent.eid ? selectedEvent : ev);
      setEvents(updatedEvents);
    } catch (error) {
      console.error('Failed to update event:', error);
    }
  };

  const bg = useColorModeValue('white', 'gray.800');
  const color = useColorModeValue('gray.800', 'white');

  return (
    <Container maxW="container.xl" p={5}>
      <VStack spacing={5} align="stretch">
        <Heading size="lg" mb={4} color="white">All Events</Heading>
        {isLoading ? (
          <Text>Loading...</Text>
        ) : error ? (
          <Text color="red.500">Error: {error}</Text>
        ) : (
          events.map(event => (
            <Box key={event.eid} p={5} shadow="md" borderWidth="1px" bg={bg} color={color}>
              <Text fontWeight="bold">{event.question}</Text>
              <IconButton
                icon={<MdModeEdit />}
                onClick={() => handleEdit(event)}
                aria-label="Edit Event"
                float="right"
              />
              <IconButton
                icon={<MdModeEdit />}
                color="blue"
                onClick={() => handleOnchainEdit(event)}
                aria-label="Edit Event Onchain"
                float="right"
              />
              <Text mt={2}>{event?.eventDetails?.description}</Text>
              <Text mt={2}>Event Type: {event?.eventDetails?.eventType}</Text>
              <Text mt={2}>Status: {event?.status}</Text>
              <Text mt={2}>Start Date: {new Date(event.startDate).toLocaleDateString()}</Text>
              <Text mt={2}>Deadline: {new Date(event.deadline).toLocaleDateString()}</Text>
              <Text mt={2}>Token Name: {event?.tokenName}</Text>
              <Text mt={2}>Total Amount in Pool: {event.stakingDetails?.totalPools.map((option, index) => (
                <Text key={index}>{option.amount} {option.token}</Text>
              ))}</Text>
              <Text mt={2}>Total Predictions: {event.stakingDetails?.stakes?.length}</Text>
              <Text mt={2}>Result: {event.result?.outcome}</Text>
            </Box>
          ))
        )}
      </VStack>

     {/* Modal for editing result onchain */}
      <Modal isOpen={onchainModal} onClose={() => setOnchainModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Result Onchain</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedEvent && (
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Result onchain: True or False</FormLabel>
                  <Input name="resultBoolean" value={selectedEvent?.resultBoolean || ''} onChange={handleChange} />
                </FormControl>
                <Button colorScheme="blue" onClick={handleOnchainUpdate}>Update Event</Button>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal for editing an event */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedEvent && (
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Question</FormLabel>
                  <Input name="question" value={selectedEvent?.question} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea name="description" value={selectedEvent?.eventDetails?.description || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Image URL</FormLabel>
                  <Input name="image" value={selectedEvent?.eventDetails.image || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Event Type</FormLabel>
                  <Select name="eventType" value={selectedEvent?.eventDetails?.eventType || 'crypto'} onChange={handleChange}>
                    <option value="crypto">Cryptocurrency</option>
                    <option value="politics">Politics</option>
                    <option value="blast">Blast Ecosystem</option>
                    <option value="real-world">Real World</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Pool Type</FormLabel>
                  <Select name="poolType" value={selectedEvent?.poolType || 'scorefam'} onChange={handleChange}>
                    <option value="scorefam">Scorefam</option>
                    <option value="blastoff">BlastOff</option>
                    <option value="blasterswap">Blaster</option>
                    <option value="andy">Andy</option>
                    <option value="bladeswap">Bladeswap</option>
                    <option value="djenn">Djenn</option>
                    <option value="thrusterfi">ThrusterFi</option>
                    <option value="wasabi">Wasabi</option>
                    <option value="yes">Yes</option>
                    <option value="iconcast">Iconcast</option>
                    <option value="partner">Partner</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Target Value</FormLabel>
                  <Input type="number" name="targetValue" value={selectedEvent?.targetValue || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Token Name</FormLabel>
                  <Input name="tokenName" value={selectedEvent?.tokenName || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Token Price</FormLabel>
                  <Input name="tokenPrice" value={selectedEvent?.tokenPrice || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Option One Name</FormLabel>
                  <Input name="optionOne" value={selectedEvent?.optionOne || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Option Two Name</FormLabel>
                  <Input name="optionTwo" value={selectedEvent?.optionTwo || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Result</FormLabel>
                  <Input name="outcome" value={selectedEvent?.result?.outcome || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Result onchain: True or False</FormLabel>
                  <Input name="resultBoolean" value={selectedEvent?.resultBoolean || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Start Date</FormLabel>
                  <Input type="datetime-local" name="startDate" value={selectedEvent?.startDate || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Deadline</FormLabel>
                  <Input type="datetime-local" name="deadline" value={selectedEvent?.deadline || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Twitter Handle</FormLabel>
                  <Input name="twitter" value={selectedEvent?.twitter || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Telegram Handle</FormLabel>
                  <Input name="telegram" value={selectedEvent?.telegram || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Discord Handle</FormLabel>
                  <Input name="discord" value={selectedEvent?.discord || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Website</FormLabel>
                  <Input name="website" value={selectedEvent?.website || ''} onChange={handleChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select name="status" value={selectedEvent?.status || 'upcoming'} onChange={handleChange}>
                    <option value="upcoming">Upcoming</option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                    <option value="completed">Completed</option>
                    <option value="cancelled">Cancelled</option>
                  </Select>
                </FormControl>
                <Button colorScheme="blue" onClick={handleUpdate}>Update Event</Button>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default AllEvents;
