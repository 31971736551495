import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import React from "react";
import AdminSideBarNav from "./AdminSideBarNav";

export default function MobileAdminSideNavDrawer({ isOpen, onClose }) {
  const firstField = React.useRef();

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      initialFocusRef={firstField}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color={"#FFFFFF"} />

        <DrawerBody bg="#121B45" p={0}>
          <AdminSideBarNav />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
