import { useContext, useEffect, useState } from "react";
import { Box, Grid, VStack, Heading } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/hooks";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";

import { AppButton, AppForm, AppModal, VideoCard } from "../../../components";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/toast";

import { Textarea } from "@chakra-ui/textarea";
import { client } from "../../../api/client";
import { isEmpty } from "../../../utils/helpers";
import { AuthContext } from "../../../store/AuthContext";
import { API_URL } from "../../../constants/urls";

export default function TutorialVideosSection() {
  const [tutorialVideos, setTutorialVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    admin: { token },
  } = useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit } = useForm();

  const toast = useToast();

  const registerOptions = {
    title: {
      required: "This is required",
    },
    description: {
      required: "This is required",
    },
    videourl: {
      required: "This is required",
    },
  };

  const onSubmit = async (videodata) => {
    setLoading(true);

    try {
      const { data, error } = await client({
        method: "POST",
        payload: videodata,
        url: `${API_URL}/api/admin/putvideo`,
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setLoading(false);

      if (error) throw new Error("Couldn't upload video.");

      if (data) setTutorialVideos(data);

      toast({
        title: "Success",
        description: `Video upload success`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
      return;
    } catch (error) {
      toast({
        title: "Error",
        description: "Couldn't upload video.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const fetchTutorialVideos = async () => {
    try {
      const { data, error } = await client({
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        url: `${API_URL}/api/admin/videos`,
      });

      if (error) throw error;

      if (data) setTutorialVideos(data);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Couldn't fetch Videos",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const url = `${API_URL}/api/admin/video/${id}`;

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.status !== 200) throw new Error("Couldn't delete video.");

      const filteredVideos = tutorialVideos.filter((video) => video._id !== id);
      setTutorialVideos([...filteredVideos]);

      toast({
        title: "Sucess",
        description: "Video deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.log("ERROR ", error);
    }
  };

  useEffect(() => {
    fetchTutorialVideos();
  }, []);

  return (
    <VStack align="left" spacing={4} pb="5rem">
      <Heading
        as="h5"
        color="#c1c1c1"
        // color='#162B4D'
        fontSize="14px"
        fontWeight="semibold"
      >
        Tutorial videos
      </Heading>

      <Box>
        <AppButton
          buttonStyle={{
            bgColor: "#2D46B2",
            px: "2rem",
            py: "1rem",
            size: "md",
            fontSize: "12px",
            fontWeight: "semibold",
            color: "#ffffff",
          }}
          label="Add new video"
          onClick={onOpen}
        />

        <AppModal
          headerTitle="Upload thumbnail"
          headerStyle={{
            textAlign: "left",
            color: "#162B4D",
            fontWeight: "semibold",
          }}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setLoading(false);
          }}
          footerVisible={false}
          containerStyle={{
            pt: "1rem",
            pb: "2rem",
          }}
          // isCentered={true}
        >
          <VStack spacing={4} align="left">
            <AppForm onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel
                    htmlFor="video-url"
                    color="#162B4D"
                    fontSize="13px"
                    fontWeight="medium"
                  >
                    Video URL
                  </FormLabel>
                  <Input
                    id="video-url"
                    fontSize="13px"
                    placeholder="Video URL"
                    {...register("videourl", registerOptions.videourl)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    htmlFor="title"
                    color="#162B4D"
                    fontSize="13px"
                    fontWeight="medium"
                  >
                    Title
                  </FormLabel>
                  <Input
                    id="title"
                    fontSize="13px"
                    placeholder="Video title"
                    {...register("title", registerOptions.title)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    htmlFor="title"
                    color="#162B4D"
                    fontSize="13px"
                    fontWeight="medium"
                  >
                    Tutorial details
                  </FormLabel>
                  <Textarea
                    fontSize="13px"
                    type="unstyled"
                    placeholder="Video description"
                    {...register("description", registerOptions.description)}
                  />
                </FormControl>
              </VStack>
              {/* <Center> */}
              <AppButton
                buttonStyle={{
                  bgColor: "#2D46B2",
                  px: "2rem",
                  py: "1rem",
                  size: "md",
                  fontSize: "12px",
                  fontWeight: "semibold",
                  color: "#ffffff",
                  width: "100%",
                }}
                label="Save Video"
                isCentered={true}
                type="submit"
                loading={loading}
              />
              {/* </Center> */}
            </AppForm>
          </VStack>
        </AppModal>
      </Box>

      <Box>
        <Grid
          templateColumns={{
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(4, 1fr)",
            base: "repeat(2, 1fr)",
          }}
          gap={[7, 20, 20]}
        >
          {!isEmpty(tutorialVideos) &&
            tutorialVideos.map((video, index) => (
              <VideoCard
                key={index}
                title={video.title}
                videourl={video.videourl}
                description={video.description}
                thumbnail={video.thumbnail}
                handleDelete={() => handleDelete(video._id)}
              />
            ))}
        </Grid>
      </Box>
    </VStack>
  );
}
