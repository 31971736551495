import { useEffect, useState } from "react";
import { Box, Center, Text, VStack } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/hooks";

import {
  AppButton,
  AppModal,
  DesktopThumbnail,
  MobileThumbnails,
  UploadInput,
} from "../../../components";
import { uploadThumbnail } from "../../../api/s3upload";
import { API_URL } from "../../../constants/urls";
import { useToast } from "@chakra-ui/toast";
import { MdAddCircle } from "react-icons/md";
import { isEmpty } from "../../../utils/helpers";

export default function ThumbnailSection() {
  const [desktopThumbnails, setDesktopThumbnails] = useState([]);
  const [mobileThumbnails, setMobileThumbnails] = useState([]);

  const [uploadedThumbnail, setUploadedThumbnail] = useState(null);

  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [uploadURL, setUploadURL] = useState(null);

  const [isUploading, setIsUploading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const handleThumbnailUpload = async () => {
    setIsUploading(true);

    const data = await uploadThumbnail(selectedThumbnail, uploadURL);

    setUploadedThumbnail(data);
    setUploadURL(null);

    onClose();
    setIsUploading(false);
  };

  const fetchUploadedThumbnails = async (bucketType) => {
    const url = `${API_URL}/api/admin/s3-bucket-objects/${bucketType}`;
    // const url = `http://localhost:8000/api/admin/s3-bucket-objects/${bucketType}`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const { data: thumbnailURLs } = await response.json();

      if (bucketType === "mobile") {
        setMobileThumbnails((prevState) => {
          const filter = thumbnailURLs.filter(
            (item) => !prevState.includes(item)
          );

          return [...filter, ...prevState];
        });
      } else {
        setDesktopThumbnails((prevState) => {
          const filter = thumbnailURLs.filter(
            (item) => !prevState.includes(item)
          );

          return [...filter, ...prevState];
        });
      }

      toast({
        title: "Success",
        description: `Retrieved ${bucketType} assets`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });

      return;
    }

    const { error } = (await response.json()) || "Error fetching assets";

    toast({
      title: "Error",
      description: error,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const handleDeleteThumbnail = async (thumbnail, bucketType) => {
    console.log("bucket type ", bucketType);
    if (bucketType === "mobile") {
      setMobileThumbnails((prevState) => {
        const filter = prevState.filter((item) => item !== thumbnail);

        return [...filter];
      });
    } else {
      setDesktopThumbnails((prevState) => {
        const filter = prevState.filter((item) => item !== thumbnail);

        return [...filter];
      });
    }

    const thumbnailkey = thumbnail.split(".com/")[1];

    const url = `${API_URL}/api/admin/s3-bucket-object/${thumbnailkey}/?type=${bucketType}`;
    // const url = `http://localhost:8000/api/admin/s3-bucket-object/${thumbnailkey}/?type=${bucketType}`;

    const response = await fetch(url, {
      method: "DELETE",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status !== 200) {
      const { error } = (await response.json()) || "Error fetching assets";

      toast({
        title: "Error",
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      return;
    }

    toast({
      title: "Success",
      description: "Assets deleted",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    return;
  };

  useEffect(() => {
    fetchUploadedThumbnails("mobile");
    fetchUploadedThumbnails("desktop");
  }, [uploadedThumbnail]);

  return (
    <Box mb="4rem">
      <Box mb="1rem">
        <AppButton
          buttonStyle={{
            bgColor: "#2D46B2",
            px: "2rem",
            py: "1rem",
            size: "md",
            fontSize: "12px",
            fontWeight: "medium",
            color: "#ffffff",
          }}
          label="Add new thumbnail"
          onClick={onOpen}
        />

        <AppModal
          headerTitle="Upload tutorial video"
          headerStyle={{
            textAlign: "center",
            color: "#162B4D",
            fontWeight: "semibold",
          }}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setUploadURL(null);
          }}
          footerVisible={false}
          containerStyle={{
            pt: "1rem",
            pb: "2rem",
          }}
        >
          <VStack spacing={4}>
            <Box width="100%">
              <UploadInput
                title="Desktop resolution"
                setSelectedThumbnail={setSelectedThumbnail}
                setUploadURL={setUploadURL}
                bucketType="desktop"
              />
              <UploadInput
                title="Mobile resolution"
                setSelectedThumbnail={setSelectedThumbnail}
                setUploadURL={setUploadURL}
                bucketType="mobile"
              />
            </Box>

            <AppButton
              buttonStyle={{
                bgColor: "#2D46B2",
                px: "2rem",
                py: "1rem",
                size: "md",
                fontSize: "12px",
                fontWeight: "semibold",
                color: "#ffffff",
                width: "80%",
              }}
              label="Upload"
              isCentered={true}
              onClick={handleThumbnailUpload}
              disable={uploadURL === null}
              loading={isUploading}
            />
          </VStack>
        </AppModal>
      </Box>

      {!isEmpty(mobileThumbnails) || !isEmpty(desktopThumbnails) ? (
        <>
          <Box mb="1rem">
            <Box mb=".5rem">
              <Text fontWeight="medium" fontSize="12px" color="#2D46B2">
                Desktop Thumbnails
              </Text>
            </Box>
            <DesktopThumbnail
              thumbnails={desktopThumbnails}
              deleteThumbnail={(thumbnailURL) =>
                handleDeleteThumbnail(thumbnailURL, "desktop")
              }
              onOpen={onOpen}
            />
          </Box>

          <Box>
            <Box mb=".5rem">
              <Text fontWeight="medium" fontSize="12px" color="#2D46B2">
                Mobile Thumbnails
              </Text>
            </Box>
            <MobileThumbnails
              thumbnails={mobileThumbnails}
              deleteThumbnail={(thumbnailURL) =>
                handleDeleteThumbnail(thumbnailURL, "mobile")
              }
              onOpen={onOpen}
            />
          </Box>
        </>
      ) : (
        <Center
          height="10rem"
          borderStyle="dashed"
          borderWidth="3px"
          borderColor="lightblue"
          borderRadius="lg"
          bg="rgba(10,10,10,0.3)"
        >
          <MdAddCircle
            size="40px"
            color="#2D46B2"
            cursor="pointer"
            onClick={onOpen}
          />
        </Center>
      )}
    </Box>
  );
}
