import React, { useContext } from "react";
import { Box, Flex, Heading, HStack } from "@chakra-ui/layout";
import { AppLogo, AppButton } from "./";
import { AuthContext } from "../store/AuthContext";
import { useNavigate } from "react-router-dom";
import { IconButton, Image } from "@chakra-ui/react";
import { PALM } from "../constants/images";
import { FiMenu } from "react-icons/fi";
import MobileAdminSideNavDrawer from "./MobileAdminSideNavDrawer";

export default function AppNavbar() {
  const [isMobileDrawer, setIsMobileDrawer] = React.useState();
  const { dispatch, admin } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <Flex justify="space-between" py="1rem" align="center" mb="1rem">
      {/* <AppLogo
        imageStyle={{
          width: "120px",
          height: "30px",
        }}
      /> */}
      <Box display={{ lg: "none" }}>
        <IconButton
          onClick={() => setIsMobileDrawer(true)}
          bg="transparent"
          color="#FFFFFF"
          minW="fit-content"
          h="fit-content"
          _focus={{ boxShadow: "none" }}
          p={0}
          icon={<FiMenu fontSize="24px" />}
        />
        <MobileAdminSideNavDrawer
          isOpen={isMobileDrawer}
          onClose={() => setIsMobileDrawer(false)}
        />
      </Box>

      <HStack display={{ base: "none", lg: "flex" }}>
        <Heading
          as="h1"
          // color='#162B4D'
          color="#FFFFFF"
          fontSize="26px"
          fontWeight="bold"
        >
          Hey, {admin?.username}
        </Heading>

        <Image src={PALM} boxSize="30px" />
      </HStack>

      <AppButton
        buttonStyle={{
          bgColor: "#2D46B2",
          px: "1.5rem",
          py: "1rem",
          size: "md",
          fontSize: "14px",
          fontWeight: "semibold",
          color: "#ffffff",
        }}
        label="Log Out"
        onClick={logout}
      />
    </Flex>
  );
}
