import { Box, Select, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import axios from "axios";

import { useEffect, useState } from "react";
import { DiscountImg, UserThree } from "../../constants/images";
import GameNUserCard from "./DashboardComponents/GameNUserCard";
import GameUserGraphCard from "./DashboardComponents/GameUserGraphCard";
//import { MapContainer } from "react-leaflet";

export default function DashboardPage() {
  const [totalUsers, setTotalUsers] = useState(null);
  const [todayUsers, setTodayUsers] = useState(null);
  const [yesterdayUsers, setYesterdayUsers] = useState(null);
  const [weeklyActiveUsers, setWeeklyActiveUsers] = useState([]);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState([]);
  const [totalBookingCodes, setTotalBookingCodes] = useState(null);
  const [totalGamesPlayed, setTotalGamesPlayed] = useState(null);
  const [totalScoresPlayed, setTotalScoresPlayed] = useState(null);
  const [totalScoresWon, setTotalScoresWon] = useState(null);
  const [totalUserRating, setTotalUserRating] = useState(null);
  const [totalSftStaked, setTotalSftStaked] = useState(null);
  const [totalActiveGamers, setTotalActiveGamers] = useState(null);
  const [totalReferredUsers, setTotalReferredUsers] = useState(null);
  const [totalReferredEarned, setTotalReferredEarned] = useState(null);
  const [todayScoresGame, setTodayScoreGames] = useState(null);
  const [weeklyGamesPlayed, setWeeklyGamesPlayed] = useState(null);

  const [totalFlexibleGames, setTotalFlexibleGames] = useState(null);
  const [todayFlexibleGames, setTodayFlexibleGames] = useState(null);
  const [yesterdayFlexibleGames, setYesterdayFlexibleGames] = useState(null);
  const [weeklyFlexibleGames, setWeeklyFlexibleGames] = useState(null);

  const [totalLockedGames, setTotalLockedGames] = useState(null);
  const [todayLockedGames, setTodayLockedGames] = useState(null);
  const [yesterdayLockedGames, setYesterdayLockedGames] = useState(null);
  const [weeklyLockedGames, setWeeklyLockedGames] = useState(null);

  const fetchTotalUsers = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-no-users`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalUsers(data + 9764);

      return;
    }
  };

  const fetchTodayUsers = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-today-users`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTodayUsers(data);

      return;
    }
  };

  const fetchYesterdayUsers = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-yesterday-users`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setYesterdayUsers(data.data);

      return;
    }
  };

  const fetchWeeklyActiveUsers = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-weekly-active-users`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setWeeklyActiveUsers(data.activeUsers);

      return;
    }
  };

  const fetchMonthlyActiveUsers = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-monthly-active-users`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setMonthlyActiveUsers(data + 7230);

      return;
    }
  };

  const fetchTotalBookingCodes = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-bookings`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalBookingCodes(data);

      return;
    }
  };

  const fetchTotalScoresPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-games-played`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalScoresPlayed(data.data);

      return;
    }
  };

  const fetchTotalReferrals = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-referred-users`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalReferredUsers(data.data);
      return;
    }
  };

  const fetchTotalReferralsEarned = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-referred-amount`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalReferredEarned(data.data);
      return;
    }
  };

  const fetchTotalScoresWon = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-amount-won`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalScoresWon(data.data);

      return;
    }
  };

  const fetchUserRating = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-users-rating`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalUserRating(data.data);

      return;
    }
  };

  const fetchTodayScoreGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-today-scores-played`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTodayScoreGames(data);
      return;
    }
  };

  const fetchWeeklyScoreGame = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-weekly-games-played`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setWeeklyGamesPlayed(data);

      return;
    }
  };

  const fetchFlexibleGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-flexible-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalFlexibleGames(data);
      return;
    }
  };

  const fetchTodayFlexibleGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-today-flexible-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      console.log("Today's flexible games played: ", data);
      setTodayFlexibleGames(data);
      return;
    }
  };

  const fetchYesterdayFlexibleGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-yesterday-flexible-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setYesterdayFlexibleGames(data.data);
      return;
    }
  };

  const fetchWeeklyFlexibleGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-weekly-flexible-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setWeeklyFlexibleGames(data.data);
      return;
    }
  };

  const fetchLockedGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-total-locked-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTotalLockedGames(data);
      return;
    }
  };

  const fetchTodayLockedGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-today-locked-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setTodayLockedGames(data);
      return;
    }
  };

  const fetchYesterdayLockedGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-yesterday-locked-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setYesterdayLockedGames(data.data);
      return;
    }
  };

  const fetchWeeklyLockedGamesPlayed = async () => {
    const url = `https://api-v2.scorefam.org/api/admin/get-weekly-locked-games`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setWeeklyLockedGames(data.data);
      return;
    }
  };


  const userLists = [
    {
      listTitle: "Total Users",
      listAmount: totalUsers,
      listImg: UserThree,
    },
    {
      listTitle: "Weekly Active Users",
      listAmount: weeklyActiveUsers,
      listImg: UserThree,
    },
    {
      listTitle: "Yesterday Registered Users",
      listAmount: yesterdayUsers,
      listImg: UserThree,
    },
    {
      listTitle: "Today Registered Users",
      listAmount: todayUsers,
      listImg: UserThree,
    },
  ];

  const scoreLists = [
    {
      listTitle: "Total Games Played",
      listAmount: totalScoresPlayed,
      listImg: UserThree,
    },
    {
      listTitle: "Total Amount Won",
      listAmount: `$ ${totalScoresWon}`,
      listImg: DiscountImg,
    },
    {
      listTitle: "Today's Games Played",
      listAmount: todayScoresGame,
      listImg: UserThree,
    },
    {
      listTitle: "7 Days Games Played",
      listAmount: weeklyGamesPlayed,
      listImg: UserThree,
    },
     // {
    //   listTitle: "Average User Rating",
    //   listAmount: Number(totalUserRating).toFixed(2),
    //   listImg: UserThree,
    // },
  ];

  const flexibleLists = [
    {
      listTitle: "Total Games Played",
      listAmount: totalFlexibleGames,
      listImg: UserThree,
    },
    {
      listTitle: "Today's Games Played",
      listAmount: todayFlexibleGames,
      listImg: DiscountImg,
    },
    {
      listTitle: "Yesterday's Games Played",
      listAmount: yesterdayFlexibleGames,
      listImg: DiscountImg,
    },
    {
      listTitle: "7 Days Games Played",
      listAmount: weeklyFlexibleGames,
      listImg: DiscountImg,
    },
    // {
    //   listTitle: "Booking Codes Created",
    //   listAmount: totalBookingCodes,
    //   listImg: DiscountImg,
    // },
  ];

  const lockedLists = [
    {
      listTitle: "Total Games Played",
      listAmount: totalLockedGames,
      listImg: UserThree,
    },
    {
      listTitle: "Today's Games Played",
      listAmount: todayLockedGames,
      listImg: DiscountImg,
    },
    {
      listTitle: "Yesterday's Games Played",
      listAmount: yesterdayLockedGames,
      listImg: DiscountImg,
    },
    {
      listTitle: "7 Days Games Played",
      listAmount: weeklyLockedGames,
      listImg: DiscountImg,
    },
  ];

  const referrals = [
    {
      listTitle: "Total Referrals",
      listAmount: totalReferredUsers,
      listImg: UserThree,
    },
    {
      listTitle: "Successful Referrals",
      listAmount: `${totalReferredEarned}`,
      listImg: DiscountImg,
    },
    {
      listTitle: "Referrals Amount Won",
      listAmount: `$ ${totalReferredEarned}`,
      listImg: DiscountImg,
    },
  ];

  const gameLists = [
    {
      listTitle: "Total Games Played",
      listAmount: totalGamesPlayed,
      listImg: UserThree,
    },
    {
      listTitle: "Total SFT Staked",
      listAmount: totalSftStaked,
      listImg: DiscountImg,
    },
    {
      listTitle: "Monthly Active Users (Stakes)",
      listAmount: totalActiveGamers,
      listImg: UserThree,
    },
    {
      listTitle: "Today Registered Users",
      listAmount: todayUsers,
      listImg: UserThree,
    },
  ];

  useEffect(() => {
    fetchTotalUsers();
    fetchYesterdayUsers();
    fetchTotalBookingCodes();
    fetchTotalScoresPlayed();
    fetchTodayUsers();
    fetchTotalScoresWon();
    fetchUserRating();
    fetchMonthlyActiveUsers();
    fetchTodayScoreGamesPlayed();
    fetchWeeklyActiveUsers();
    fetchTotalReferrals();
    fetchTotalReferralsEarned();
    fetchFlexibleGamesPlayed();
    fetchTodayFlexibleGamesPlayed();
    fetchYesterdayFlexibleGamesPlayed();
    fetchWeeklyFlexibleGamesPlayed();
    fetchLockedGamesPlayed();
    fetchTodayLockedGamesPlayed();
    fetchYesterdayLockedGamesPlayed();
    fetchWeeklyLockedGamesPlayed();
    fetchWeeklyScoreGame();
  }, []);

  return (
    <Box>
      <Text
        color="#EFF1F6"
        fontSize={["24px", "", "30px"]}
        lineHeight={["30px", "", "45px"]}
        fontWeight={600}
      >
        Dashboard
      </Text>
      <Select
        w="150px"
        bg="#162240"
        borderRadius="4px"
        color="#BEC9DA"
        placeholder="This week"
        border="none"
        my="8px"
      >
        <option>This week</option>
      </Select>
      <Text
        color="#BEC9DA"
        fontSize="12px"
        fontWeight={"600"}
        lineHeight="18px"
        mb={5}
        mt={5}
      >
        Users Summary
      </Text>
      <SimpleGrid columns={["1", "2", "", "4"]} gap="32px" mb={"15px"}>
        {userLists.map((cardUser, i) => (
          <GameNUserCard
            title={cardUser.listTitle}
            amount={cardUser.listAmount}
            cardImg={cardUser.listImg}
          />
        ))}
      </SimpleGrid>
      <Text
        color="#BEC9DA"
        fontSize="12px"
        fontWeight={"600"}
        lineHeight="18px"
        mb={5}
        mt={5}
      >
        Score Games Summary
      </Text>
      <SimpleGrid columns={["1", "2", "", "4"]} gap="32px" mb={"15px"}>
        {scoreLists.map((cardUser, i) => (
          <GameNUserCard
            title={cardUser.listTitle}
            amount={cardUser.listAmount}
            cardImg={cardUser.listImg}
          />
        ))}
      </SimpleGrid>
      <Text
        color="#BEC9DA"
        fontSize="12px"
        fontWeight={"600"}
        lineHeight="18px"
        mb={5}
        mt={5}
      >
        Flexible Games Summary
      </Text>
      <SimpleGrid columns={["1", "2", "", "4"]} gap="32px" mb={"15px"}>
        {flexibleLists.map((cardUser, i) => (
          <GameNUserCard
            title={cardUser.listTitle}
            amount={cardUser.listAmount}
            cardImg={cardUser.listImg}
          />
        ))}
      </SimpleGrid>
      <Text
        color="#BEC9DA"
        fontSize="12px"
        fontWeight={"600"}
        lineHeight="18px"
        mb={5}
        mt={5}
      >
        Locked Games Summary
      </Text>
      <SimpleGrid columns={["1", "2", "", "4"]} gap="32px" mb={"15px"}>
        {lockedLists.map((cardUser, i) => (
          <GameNUserCard
            title={cardUser.listTitle}
            amount={cardUser.listAmount}
            cardImg={cardUser.listImg}
          />
        ))}
      </SimpleGrid>
      <Text
        color="#BEC9DA"
        fontSize="12px"
        fontWeight={"600"}
        lineHeight="18px"
        mb={5}
        mt={5}
      >
        Referrals Summary
      </Text>
      <SimpleGrid columns={["1", "2", "", "4"]} gap="32px" mb={"15px"}>
        {referrals.map((cardUser, i) => (
          <GameNUserCard
            title={cardUser.listTitle}
            amount={cardUser.listAmount}
            cardImg={cardUser.listImg}
          />
        ))}
      </SimpleGrid>
      {/* <Text
        color="#BEC9DA"
        fontSize="12px"
        fontWeight={"600"}
        lineHeight="18px"
        mb={"5px"}
      >
        Games Summary
      </Text>
      <SimpleGrid columns={["1", "2", "", "4"]} gap="32px">
        {gameLists.map((cardGame, i) => (
          <GameNUserCard
            title={cardGame.listTitle}
            amount={cardGame.listAmount}
            cardImg={cardGame.listImg}
          />
        ))}
      </SimpleGrid> */}

      {/* <MapContainer center={[0, 0]} zoom={2}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {monthlyActiveUsers.map((user) => (
          <Marker
            key={user._id}
            position={[user.platformipLatitude, user.platform.ipLongitude]}
          >
            <Popup>{user.platform.ipAddress}</Popup>
          </Marker>
        ))}
      </MapContainer> */}

      <SimpleGrid columns={["1", "", "2"]} gap="32px" mt="33px">
        <GameUserGraphCard
          cardType={"Games Played"}
          currency={"$"}
          monFlexible="20%"
          monMultiflex="30%"
          tueFlexible="20%"
          tueMultiflex="40%"
          wedFlexible="30%"
          wedMultiflex="20%"
          thuFlexible="50%"
          thuMultiflex="10%"
          friFlexible="30%"
          friMultiflex="20%"
          satFlexible="35%"
          satMultiflex="40%"
          sunFlexible="15%"
          sunMultiflex="40%"
        />
        <GameUserGraphCard
          cardType={"Users"}
          monFlexible="50%"
          monMultiflex="30%"
          tueFlexible="20%"
          tueMultiflex="40%"
          wedFlexible="30%"
          wedMultiflex="20%"
          thuFlexible="50%"
          thuMultiflex="10%"
          friFlexible="30%"
          friMultiflex="20%"
          satFlexible="35%"
          satMultiflex="40%"
          sunFlexible="15%"
          sunMultiflex="40%"
        />
      </SimpleGrid>
    </Box>
  );
}
