import {
  Center,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { useToast } from "@chakra-ui/toast";

export default function CouponsTable() {
  const [couponCodes, setCouponCode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const fetchCouponCodes = async () => {
    const url = `https://api-v2.scorefam.org/api/coupon/all`;

    const response = await fetch(url, {
      method: "GET",
      body: null,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      console.log("This is data", data);
      setCouponCode(data.data);

      return;
    }
  };

  const deleteCouponCode = async (couponId) => {
    try {
      //
      setIsLoading(true);
      const url = `https://api-v2.scorefam.org/api/coupon/?${couponId}`;

      const response = await fetch(url, {
        method: "DELETE",
        body: null,
        headers: {
          Accept: "application/json",
        },
      });
      if (response.status === 200) {
        fetchCouponCodes();

        toast({
          title: "Success",
          description: "Coupon Code Deleted Successfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  function convertToDate(dateString) {
    return new Date(dateString);
  }

  function isCouponExpired(couponDate) {
    const currentDate = new Date();
    return currentDate > couponDate;
  }

  const tableHeading = [
    "Game Code",
    "Multiplier",
    "Date Created",
    "Expiry Date",
    "",
  ];

  useEffect(() => {
    fetchCouponCodes();
  }, []);

  return (
    <TableContainer>
      <Table colorScheme="whiteAlpha">
        <Thead>
          <Tr>
            {tableHeading.map((headings) => (
              <Th
                color="#BEC9DA"
                fontSize="18px"
                lineHeight="27px"
                fontWeight={500}
              >
                {headings}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {couponCodes.map((couponCode, index) => (
            <Tr key={index}>
              <Td color="#BEC9DA" fontSize="14px" lineHeight="21px">
                {couponCode.code} ({couponCode.users.length} users)
              </Td>
              <Td
                color="#BEC9DA"
                fontSize="14px"
                lineHeight="21px"
                textAlign={"center"}
              >
                {couponCode.multiplier}
              </Td>
              <Td color="#BEC9DA" fontSize="14px" lineHeight="21px">
                {couponCode.expiryDate}
              </Td>
              <Td>
                <HStack>
                  <Text color="#BEC9DA" fontSize="14px" lineHeight="21px">
                    {couponCode.expiryDate}
                  </Text>
                  {isCouponExpired(convertToDate(couponCode.expiryDate)) ? (
                    <Center
                      w="74px"
                      h="26px"
                      bg="rgba(211, 76, 76, 0.05)"
                      border="1px solid rgba(211, 76, 76, 0.4)"
                      borderRadius="30px"
                      color="#D34C4C"
                      fontSize="12px"
                      lineHeight="18px"
                    >
                      Expired
                    </Center>
                  ) : (
                    <Center
                      w="74px"
                      h="26px"
                      bg="#EEFFF6"
                      border="1px solid #0E7F42"
                      borderRadius="30px"
                      color="#0E7F42"
                      fontSize="12px"
                      lineHeight="18px"
                    >
                      Active
                    </Center>
                  )}
                </HStack>
              </Td>
              <Td>
                <IconButton
                  onClick={() => {
                    deleteCouponCode(couponCode._id);
                  }}
                  //onClick={}
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  _focus={{ boxShadow: "none" }}
                  icon={<BsTrash color="#FFFFFF" fontSize="20px" />}
                  isLoading={isLoading}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
