import { Box, Flex, HStack, Select, Square, Text } from "@chakra-ui/react";
import React from "react";
import GraphColumn from "./GraphColumn";

export default function GameUserGraphCard({
  currency,
  cardType,
  monFlexible,
  monMultiflex,
  tueFlexible,
  tueMultiflex,
  wedFlexible,
  wedMultiflex,
  thuFlexible,
  thuMultiflex,
  friFlexible,
  friMultiflex,
  satFlexible,
  satMultiflex,
  sunFlexible,
  sunMultiflex,
}) {
  const daysChartBar = [
    {
      day: "Mon",
      dayFlexible: monFlexible,
      dayMultiflex: monMultiflex,
    },
    {
      day: "Tue",
      dayFlexible: tueFlexible,
      dayMultiflex: tueMultiflex,
    },
    {
      day: "Wed",
      dayFlexible: wedFlexible,
      dayMultiflex: wedMultiflex,
    },
    {
      day: "Thu",
      dayFlexible: thuFlexible,
      dayMultiflex: thuMultiflex,
    },
    {
      day: "Fri",
      dayFlexible: friFlexible,
      dayMultiflex: friMultiflex,
    },
    {
      day: "Sat",
      dayFlexible: satFlexible,
      dayMultiflex: satMultiflex,
    },
    {
      day: "Sun",
      dayFlexible: sunFlexible,
      dayMultiflex: sunMultiflex,
    },
  ];

  return (
    <Box
      bg="linear-gradient(100.19deg, #0A1636 2.72%, #08122B 100%)"
      borderRadius="8px"
      border="1px solid"
      borderColor="#8F7FEF #9747FF"
      p="16px"
    >
      <HStack justify="space-between">
        <Text
          color="#EFF1F6"
          fontSize="18px"
          lineHeight="27px"
          fontWeight={500}
        >
          {cardType}
        </Text>
        <Select
          w="150px"
          bg="#162240"
          borderRadius="4px"
          color="#BEC9DA"
          placeholder="This week"
          border="none"
        >
          <option>This week</option>
        </Select>
      </HStack>
      <HStack spacing="12px" mt="4px" mb="40px">
        <HStack spacing="4px">
          <Square bg="#023ED4" size="6px"></Square>
          <Text color="#BEC9DA" fontSize="14px" lineHeight="21px">
            Flexible
          </Text>
        </HStack>
        <HStack spacing="4px">
          <Square bg="#55C388" size="6px"></Square>
          <Text color="#BEC9DA" fontSize="14px" lineHeight="21px">
            MultiFlex
          </Text>
        </HStack>
      </HStack>

      <Flex gap="9px">
        <Flex flexDirection="column" h="152px" justifyContent="space-between">
          {[5, 4, 3, 2, 1, 0].map((nums) => (
            <Text key={nums} color="#BEC9DA" fontSize="10px" lineHeight="15px">
              {currency}
              {nums}K
            </Text>
          ))}
        </Flex>

        <Flex justifyContent="space-between" flex={1}>
          {daysChartBar.map((bars, i) => (
            <GraphColumn
              key={i}
              days={bars.day}
              greenBar={bars.dayFlexible}
              blueBar={bars.dayMultiflex}
            />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
}
